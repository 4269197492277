div {
  .basket_preview {
    // width: 550px;
    // margin: auto;

    .layout_second {
      // padding: 20px 10px 20px 10px;
      // .header_description {
      //   text-align: center;
      //   font-size: 20px;
      //   font-weight: 500;
      // }

      // width: 550px;
      // margin: auto;
      // @media (max-width: 767px) {
      //   width: 100%;
      //   margin: 0;
      // }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .item_container {
        // margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        // padding-left: 1px;
        // justify-content: space-evenly;
        //   align-content: flex-start;
        margin: auto;
        width: 505px;
        @media (max-width: 767px) {
          width: 100%;
          margin: 0;
        }
        .item {
          width: 160px;
          margin-bottom: 25px !important;
          height: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          font-size: 6px;
          font-family: Arial, Helvetica, sans-serif;
          /*          font-weight: 600;*/
          margin: 2px 4px;
          // margin: auto;
          text-align: center;
          .item_icon {
            text-align: center;
            height: 60%;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            a,
            img {
              height: 100%;
              max-width: 100%;
              margin: auto;
            }
            padding: 5px;
          }
          .item_serial_description,
          .item_daimond_description {
            width: 100%;
            /*            padding: 0px 1px;*/
            font-size: 7px;
            font-weight: 700;
            /*            line-height: 1.5em;*/
            // max-height: 3em;
            min-height: 2em;
            white-space: break-spaces;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 2px;
            // margin-bottom: 3px;
            // margin-top: 3px;
            padding: 0px 0px 2px 0px;
            box-sizing: content-box;
          }
          .item_serial_style_no {
            width: 100%;
            padding: 1px;
          }
          .item_metal {
            width: 100%;
            padding: 1px;
          }
          .item_internal_note {
            width: 100%;
            padding: 1px;
          }
          .stone_details {
            width: 100%;
            padding: 1px;
          }
          .item_price {
            width: 100%;
            padding: 1px;
            font-style: italic;
          }
        }
      }
    }
  }

  .layout_second_header {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    top: 0.25in;
    right: 0px;
    left: 0px;
    margin: 0px 0.6in;
    // width: 100%;
    // padding-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    // border-bottom: solid rgb(184, 184, 184) 1px;
    // left: 10px;
  }

  .layout_second_footer {
    border-top: solid rgb(184, 184, 184) 1px;
    text-align: center;
    font-weight: 500;
    position: absolute;
    bottom: 0.2in;
    right: 0px;
    left: 0px;
    padding: 6px 0px 4px 0px;
    margin: 0px 0.6in;
    // margin-right: 20px;
    // width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 7px;
    font-family: Arial, Helvetica, sans-serif;

    span:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    span:nth-child(2) {
      width: 50%;
      // margin-right: 25%;
      padding-top: 10px !important;
      font-size: 6px;
    }
    .kwiat {
      max-width: 40px;
    }
    .fred {
      max-width: 150px !important;
    }
    .kwfl {
      max-width: 100px !important;
    }
    .bridal {
      max-width: 40px !important;
    }
    img {
      margin-bottom: 4px;
      // margin-top: 4px !important;
      // max-width: 100px !important;
      // max-height: 100px !important;
    }
    // left: 10px;
  }
}

.email_body_items {
  padding: 40px 0px 0px;
  .item_container {
    // display: flex;
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    /* align-items: center; */
    /* justify-content: flex-start; */
    margin: auto;
    width: 505px;
    text-align: center;
    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
    .item {
      width: 160px;
      margin-bottom: 25px !important;
      height: 200px;
      /* display: flex; */
      /* flex-direction: column; */
      /* align-items: center; */
      /* justify-content: flex-start; */
      font-size: 6px;
      font-family: Arial, Helvetica, sans-serif;
      /* font-weight: 600; */
      /* margin: 2
    px
     4
    px
    ; */
      text-align: center;
      margin: auto;
      .item_icon {
        text-align: center;
        height: 60%;
        width: 70%;
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        a,
        img {
          height: 100%;
          max-width: 100%;
          margin: auto;
        }
        padding: 5px;
      }
      .item_serial_description {
        width: 100%;
        /*            padding: 0px 1px;*/
        font-size: 7px;
        font-weight: bold;
        /*            line-height: 1.5em;*/
        /*            max-height: 3em;*/
        white-space: break-spaces;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 3px;
        margin-top: 3px;
      }
      .item_serial_style_no {
        width: 100%;
        padding: 1px;
      }
      .item_metal {
        width: 100%;
        padding: 1px;
      }
      .item_internal_note {
        width: 100%;
        padding: 1px;
      }
      .stone_details {
        width: 100%;
        padding: 1px;
      }
      .item_price {
        width: 100%;
        padding: 1px;
        font-style: italic;
      }
    }
  }
}
