
.form-field2 {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    /* visibility: hidden; */
    display: none;
  }

  .form-field2 input {
    font-size: 14px !important;
  }

  .results-info {
    display: none !important;
  }
  
  .no-result-found {
    background-color: #f5f7f8;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px !important;
  }
  