div {
  .basket_preview {
    // width: 500px;
    // margin: auto;
    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
    .layout_first {
      // padding: 0px 30px;
      .Item {
        height: 11in;
        // padding: 0px 10px;
        position: relative;
        .logo {
          text-align: center;
          padding: 0px 0px 20px 0px;
          width: 100%;
          img.fred_logo {
            width: 50% !important;
            margin-top: 10px;
          }
          img.kwiat_logo {
            width: 20% !important;
          }
        }
        .product_image {
          text-align: center;
          padding: 10px 0px;
          width: 100%;
          img {
            max-width: 80%;
            max-height: 220px;
          }
        }
        .product_details {
          padding-top: 20px;
          width: 100%;
          table {
            width: 100%;
            tbody {
              width: 100%;
              // tr.bordered {
              //   border-bottom: dashed rgb(184, 184, 184) 1px;
              // }
              tr {
                padding: 0px 2px 0px 2px;
                border-bottom: dashed rgb(184, 184, 184) 1px;
                td {
                  // width: 50%;
                  padding: 10px 0px;
                  font-size: 10px;
                  vertical-align: top;
                  font-family: Arial, Helvetica, sans-serif;
                  // font-family: "Proxima Nova Regular";
                  color: rgb(155, 155, 155);
                }

                td:nth-child(2) {
                  text-align: right;
                  font-weight: 200;
                  width: 69% !important;
                  color: black;
                }
                // .links{
                //   width: 100%;
                //   text-align: center;
                // }
              }
              tr:last-child {
                border-bottom: solid white !important;
              }
            }
          }
          .links {
            width: 100%;
            text-align: center;
            font-size: 8px;
            margin-top: 10px;
          }
        }
        .footer {
          border-top: solid rgb(184, 184, 184) 1px;
          padding-top: 20px;
          width: 100%;
          right: 0px;
          left: 0px;
          position: absolute;
          top: 64%;
          display: flex;
          font-size: 7px;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 300 !important;
          // align-content: center;
          justify-content: space-around;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
          }
        }
      }
    }
  }
}

// .k-pdf-export {
//   .basket_preview {
//     .layout_first {
//       .Item {
//         //         height: 11in;
//         //         padding: 0px 40px;
//         //         .logo {
//         //           text-align: center;
//         //           padding: 30px 0px 20px 0px;
//         //           img.fred_logo {
//         //             width: 50% !important;
//         //           }
//         //           img.kwiat_logo {
//         //             width: 20% !important;
//         //           }
//         //         }
//         //         .product_image {
//         //           text-align: center;
//         //           padding: 50px 0px;
//         //           img {
//         //             //   height: 100px;
//         //           }
//         //         }
//         .product_details {
//           table {
//             //             width: 100%;
//             tbody {
//               //               width: 100%;
//               tr {
//                 border-bottom: dashed rgb(184, 184, 184) 1px;
//                 td {
//                   //                   // width: 50%;
//                   //                   padding: 20px 0px;
//                   font-size: 12px;
//                   //                   vertical-align: top;
//                 }

//                 td:nth-child(2) {
//                   text-align: right;
//                   font-weight: bold;
//                   width: 70% !important;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
