.basket_preview {
  .detail-layout {
    // padding: 20px;

    .basket_preview_form_details {
      padding: 10px 0 0 0;
      table {
        tr {
          padding-bottom: 8px;
          td {
            @media (max-width: 767px) {
              font-size: 13px;
            }
            font-size: 15px;
          }
          td:nth-child(2) {
            padding-left: 5px;
          }
        }
      }
    }
    h4 {
      font-size: 17px;
      margin: 20px 0 0 0;
    }
    .basket_preview_items {
      .items {
        border-bottom: black solid 1px;
        // height: 150px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        table.item_header {
          width: 100%;
          tbody {
            tr {
              td {
                padding-left: 5px;
                pre {
                  border: none !important;
                  background-color: white !important;
                  padding: 0;
                  font-size: 13px;
                  @media (max-width: 767px) {
                    font-size: 11px;
                  }
                }
              }
              td:first-child {
                width: 40%;
              }
              td:nth-child(2) {
                width: 50%;
              }
              td:nth-child(3) {
                width: 10%;
              }
            }
          }
        }
        .item_description {
          // margin-bottom: 10px;
          .item_image {
            width: 15%;
            display: inline-block;
            // align-items: center;
            // padding: 10px;
            background-color: #fff;
            vertical-align: top;
            height: 60px;
            @media (max-width: 767px) {
              font-size: 12px;
            }
            // text-align: center;
            .product-thumbnail {
              max-width: 100%;
              max-height: 100%;
              height: 100%;
              vertical-align: baseline !important;
            }
            .dia-image-icon {
              min-width: 100%;
              min-height: 100%;
              max-height: 100%;
              margin-top: 0;
              margin-bottom: 0;
              vertical-align: baseline !important;
            }
            .product-image-icon {
              max-width: 100%;
              max-height: 100%;
              margin-top: 0;
              vertical-align: baseline !important;
            }
          }
          .item_details {
            width: 68%;
            display: inline-block;
            padding-left: 10px;
            max-height: 100%;
            table {
              width: 100%;
              tbody {
                tr {
                  td {
                    font-size: 12px;
                    @media (max-width: 767px) {
                      font-size: 10px;
                    }
                    span {
                      @media (max-width: 767px) {
                        font-size: 10px;
                      }
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
          .item_price {
            width: 17%;
            display: inline-block;
            table {
              width: 100%;
              tbody {
                tr {
                  td {
                    @media (max-width: 767px) {
                      font-size: 10px;
                    }
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
